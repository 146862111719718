import React from 'react';
import './_NavLinks.scss';

import SuperAdminNavLinks from './SuperAdminNavLinks';
import AdminNavLinks from './AdminNavLiks';
import ProfessionalNavLinks from './ProfessionalNavLinks';

const NavLinks = ({ currentUser, isOverflowing }) => {
  return (
    <ul className={`${isOverflowing ? 'side-drawer' : 'nav-links'}`}>
      {currentUser.is_professional && (
        <ProfessionalNavLinks currentUser={currentUser} />
      )}

      {currentUser.is_health_facility_admin && !currentUser.is_superadmin && (
        <AdminNavLinks />
      )}

      {currentUser.is_superadmin && <SuperAdminNavLinks />}
    </ul>
  );
};

export default NavLinks;
