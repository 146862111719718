import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router';

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  const currentUser = useSelector((state) => {
    return state.user.data;
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser)
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
        if (
          !currentUser.is_professional &&
          !currentUser.is_health_facility_admin &&
          !currentUser.is_superadmin
        )
          return (
            <Redirect
              to={{ pathname: '/home', state: { from: props.location } }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
