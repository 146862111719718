import { combineReducers } from 'redux';
import { persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { prescriptionsReducer } from 'features/patientProfile/mainFeatures/prescriptions/prescriptionSlice';
import { patientsReducer } from 'features/patients/patientsSlice';
import { professionalsReducer } from 'features/professionals/professionalsSlice';
import { medicinesReducer } from 'features/medicines/medicinesSlice';
import { userReducer } from 'features/user/userSlice';
import { advicesReducer } from 'features/advices/advicesSlice';
import { dietsReducer } from 'features/patientProfile/mainFeatures/diets/dietSlice';
import { superAdminsReducer } from 'features/superAdmins/superAdminSlice';
import { loggerReducer } from 'features/logger/loggerSlice';
import { mealsReducer } from 'features/patientProfile/mainFeatures/mealsRegister/mealsSlice';
import { examsReducer } from 'features/patientProfile/mainFeatures/exams/examsSilce';
import { medicalServicesReducer } from 'features/medicalServices/medicalServicesSlice';
import { locationsReducer } from 'features/locations/locationsSlice';
import { adminsReducer } from 'features/admins/adminsSlice';
import { chatbotReducer } from 'features/chatbot/chatbotSlice';

export const reducers = {
  user: userReducer,
  prescriptions: prescriptionsReducer,
  patients: patientsReducer,
  professionals: professionalsReducer,
  medicines: medicinesReducer,
  advCat: advicesReducer,
  diets: dietsReducer,
  superAdmins: superAdminsReducer,
  logger: loggerReducer,
  meals: mealsReducer,
  exams: examsReducer,
  medicalServices: medicalServicesReducer,
  locations: locationsReducer,
  admins: adminsReducer,
  chatbot: chatbotReducer,
};
export const rootReducer = combineReducers(reducers);

const currentVersion = 29; // update this with each new reducer change

const migrations = {
  [currentVersion]: (state) => {
    return {};
  },
};

const persistConfig = {
  key: 'root',
  version: currentVersion,
  storage,
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
