import http from '../http';

// Params is used to select the type of user
export async function getUsers(params) {
  const response = await http.get('users', { params });
  return response;
}

export async function getUser(payload) {
  const response = await http.get(`users/${payload}`, {
    params: {
      light: false,
    },
  });
  return response;
}

export async function getUserByEmail(payload) {
  const response = await http.get(`users/byemail/${payload}`);
  return response;
}

export async function createUser(payload) {
  const { rut, ...rest } = payload;
  const parsed_rut = rut.split('-');

  const response = await http.post('users', {
    rut: Number(parsed_rut[0].replace(/\./gi, '')),
    check_digit: Number(parsed_rut[1]),
    ...rest,
  });

  return response;
}

export async function editUser(payload) {
  const { id, ...data } = payload;
  let final_payload = data;
  if (final_payload.rut) {
    const sepRut = final_payload.rut.split('-');
    const is_k = sepRut[1] === 'k' || sepRut[1] === 'K';
    final_payload.rut = Number(sepRut[0].replace(/\./gi, ''));
    final_payload.check_digit = is_k ? 10 : +sepRut[1];
  }
  const response = await http.patch(`users/${id}`, final_payload);
  return response;
}

export async function deleteUser(payload) {
  const response = await http.delete(`users/${payload}`);
  return response;
}

export async function changeUserPassword(payload) {
  const user = await http.patch(`users/${payload.id}`, payload.data);
  return user;
}

export async function searchUser(payload) {
  const response = await http.get(
    `users/byrut/${payload.split('-')[0].replace(/\./gi, '')}`,
  );
  return response;
}

export async function patchUserHealthFacilities(payload) {
  const { userId, data } = payload;
  const response = await http.patch(`users/${userId}/health-facilities`, data);
  return response;
}

export async function sendEmailVerification(payload) {
  const response = await http.post(
    `users/${payload.id}/send_email_verification`,
  );
  return response;
}
