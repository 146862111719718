import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import ProtectedRoute from './protectedRoute';
import ProtectedAdminRoute from './protectedAdminRoute';
import ProtectedSuperAdminRoute from './protectedSuperAdminRoute';

import LandingPage from 'features/landingPage/LandingPage';

const PrivacyPage = lazy(() => import('features/privacy/privacy'));
const DeletionForm = lazy(() =>
  import('features/user/cognitoUserDeletion/userDeletion'),
);

const ProfessionalRegister = lazy(() =>
  import('features/professionals/professionalRegister'),
);
const AdminRegister = lazy(() => import('features/admins/adminRegister'));
const SuperAdminRegister = lazy(() =>
  import('features/superAdmins/superAdminRegister'),
);
const PatientRegister = lazy(() => import('features/patients/patientRegister'));

const LoginContainer = lazy(() => import('features/login/loginContainer'));
const PasswordRecover = lazy(() => import('features/user/passwordRecover'));
const PageNotFound = lazy(() => import('features/common/pageNotFound'));

const AuthRedirect = lazy(() => import('features/login/authRedirect'));
const SearchPatient = lazy(() =>
  import('features/patients/searchPatient/searchPatient'),
);
const PatientsList = lazy(() => import('features/patients/patientsList'));
const Logout = lazy(() => import('features/common/logout/logout'));
const PatientProfilePage = lazy(() =>
  import('features/patientProfile/profilePage'),
);
const AssignCarerForm = lazy(() =>
  import('features/patientProfile/profile/profileOptions/carers/assignCarer'),
);
const ProfessionalsList = lazy(() =>
  import('features/professionals/professionalsList'),
);
const ProfessionalProfile = lazy(() =>
  import('features/professionals/professionalProfile'),
);
const ProfilePage = lazy(() => import('features/user/userProfile/profilePage'));
const DietGrid = lazy(() =>
  import('features/patientProfile/mainFeatures/diets/dietGrid'),
);
const SuperAdminProfile = lazy(() =>
  import('features/superAdmins/superAdminProfile'),
);
const Medicines = lazy(() => import('features/medicines/medicinesPage'));
const LoggerContainer = lazy(() => import('features/logger/loggerContainer'));
const AdvicesContainer = lazy(() =>
  import('features/advices/advicesContainer'),
);
const FeetContainer = lazy(() =>
  import('features/patientProfile/mainFeatures/feet/feetContainer'),
);
const SuperAdminHealthFacilities = lazy(() =>
  import('features/superAdmins/superAdminHealthFacilities'),
);
const HealthFacilityContainer = lazy(() =>
  import('features/medicalServices/facilityPage/facilityContainer'),
);
const AdminProfile = lazy(() => import('features/admins/adminProfile'));
const AdminPatientsList = lazy(() =>
  import('features/admins/adminPatientsList'),
);
const AdminProfessionalsList = lazy(() =>
  import('features/admins/adminProfessionalsList'),
);
const AdminHealthFacilities = lazy(() =>
  import('features/admins/adminHealthFacilities'),
);
const PatientsContainer = lazy(() =>
  import('features/professionals/professionalPatients'),
);
const ProfessionalHealthFacilities = lazy(() =>
  import('features/professionals/professionalHealthFacitlities'),
);
const AdminsList = lazy(() => import('features/admins/adminsList'));
const SuperAdminsList = lazy(() =>
  import('features/superAdmins/superAdminList'),
);

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={LoginContainer} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/password-recover" component={PasswordRecover} />
        <Route exact path="/delete-account" component={DeletionForm} />
        <ProtectedRoute exact path="/profile" component={ProfilePage} />
        <ProtectedRoute exact path="/advices" component={AdvicesContainer} />
        <ProtectedRoute exact path="/medicines" component={Medicines} />
        <ProtectedRoute exact path="/patient/diets" component={DietGrid} />
        <ProtectedRoute
          exact
          path={['/patient/feet', '/patient/feet/:feet_id']}
          component={FeetContainer}
        />

        {/* REGISTER */}
        <ProtectedRoute
          exact
          path="/patient/register"
          component={PatientRegister}
        />
        <ProtectedAdminRoute
          exact
          path="/professional/register"
          component={ProfessionalRegister}
        />
        <ProtectedSuperAdminRoute
          exact
          path="/admin/register"
          component={AdminRegister}
        />
        <ProtectedSuperAdminRoute
          exact
          path="/super-admin/register"
          component={SuperAdminRegister}
        />

        {/* PATIENTS */}
        <ProtectedRoute
          exact
          path="/asigncarer/:patient_id"
          component={AssignCarerForm}
        />
        <ProtectedRoute
          exact
          path="/patient/:id"
          component={PatientProfilePage}
        />
        <ProtectedRoute
          exact
          path="/patients/search"
          component={SearchPatient}
        />
        <ProtectedRoute
          exact
          path="/patient-profile"
          component={PatientProfilePage}
        />
        <ProtectedAdminRoute exact path="/patients" component={PatientsList} />

        {/* PROFESSIONALS */}
        <ProtectedAdminRoute
          exact
          path="/professionals"
          component={ProfessionalsList}
        />
        <ProtectedRoute
          exact
          path="/professionals/:id"
          component={ProfessionalProfile}
        />
        <ProtectedRoute
          exact
          path="/professional/patients"
          component={PatientsContainer}
        />
        <ProtectedRoute
          exact
          path="/professional/health-facilities"
          component={ProfessionalHealthFacilities}
        />

        {/* ADMINS */}
        <ProtectedSuperAdminRoute exact path="/admins" component={AdminsList} />
        <ProtectedSuperAdminRoute
          exact
          path="/admins/:id"
          component={AdminProfile}
        />
        <ProtectedAdminRoute
          exact
          path="/admin/patients"
          component={AdminPatientsList}
        />
        <ProtectedAdminRoute
          exact
          path="/admin/professionals"
          component={AdminProfessionalsList}
        />
        <ProtectedAdminRoute
          exact
          path="/admin/health-facilities"
          component={AdminHealthFacilities}
        />

        {/* SUPERADMINS */}
        <ProtectedSuperAdminRoute
          exact
          path="/super-admins"
          component={SuperAdminsList}
        />
        <ProtectedSuperAdminRoute
          exact
          path="/super-admins/:id"
          component={SuperAdminProfile}
        />

        {/* MEDICAL SERVICES */}
        <ProtectedAdminRoute
          exact
          path="/health-facilities"
          component={SuperAdminHealthFacilities}
        />
        <ProtectedRoute
          exact
          path="/health-facility/:id"
          component={HealthFacilityContainer}
        />

        <ProtectedSuperAdminRoute
          exact
          path="/logger"
          component={LoggerContainer}
        />
        <ProtectedRoute exact path="/logout" component={Logout} />
        <ProtectedRoute path="/home" component={AuthRedirect} />
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
