import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createConversation,
  getUserConversations,
  getConversation,
  sendMessageToBot,
  sendFAQToBot,
  getConversationMessages,
} from '../../api/chatbot';
import thunkhandler from 'helpers/thunk_handler';
import { refreshSessionThunk } from '../user/userSlice';
import { errorHandler } from 'helpers/errorHandler';

export const chatbotState = {
  loading: false,
  loadingConversations: false,
  loadingMessages: false,
  isFirstMessage: true,
  current: {
    id: null,
    messages: [
      {
        author: 'bot',
        type: 'text',
        data: {
          text: '¡Hola! Soy tu asistente virtual en Glik ¿En qué puedo ayudarte hoy?',
        },
      },
    ],
  },
  list: [],
  error: false,
};

const fetchConversations = createAsyncThunk(
  'chatbot/fetchConversations',
  async ({ userId, skip, limit }, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      getUserConversations,
      { userId, skip, limit },
      thunkAPI.dispatch,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data, chatbotState.list);
  },
);

function removePrefix(str) {
  if (str.startsWith(': ')) {
    return str.substring(2);
  } else if (str.startsWith(':')) {
    return '';
  }
  return str;
}

function isNumeric(value) {
  return !isNaN(value) && !isNaN(parseFloat(value));
}

function get_sql_query_description(funcionName) {
  switch (funcionName) {
    case 'get_patient_health_facilities':
      return 'Buscando centros médicos.';
    case 'get_patient_info':
      return 'Buscando información del paciente.';
    case 'get_patient_prescriptions':
      return 'Buscando recetas médicas.';
    case 'get_professional_patients':
      return 'Buscando los pacientes del profesional.';
    case 'search_patient_by_name':
      return 'Buscando paciente por nombre.';
    case 'calculate_average_daily_steps':
      return 'Buscando información de los pasos del paciente.';
    case 'calculate_glycemia_report':
      return 'Buscando información de glicemia del paciente.';
    default:
      console.log('Función desconocida:');
      return null;
  }
}

export const sendMessage = createAsyncThunk(
  'chatbot/sendMessage',
  async ({ message, context, targetUser }, thunkAPI) => {
    try {
      thunkAPI.dispatch(addUserMessage(message));
      const conversationId = thunkAPI.getState().chatbot.current.id;

      // Send message y manage streaming using callback
      await sendMessageToBot(
        conversationId,
        message,
        context,
        targetUser,
        (token) => {
          const messages = thunkAPI.getState().chatbot.current.messages;
          const lastAuthor =
            messages.length > 0 ? messages[messages.length - 1].author : null;
          const lastIsTool =
            messages.length > 0 ? messages[messages.length - 1].type : null;

          try {
            if (isNumeric(token)) {
              throw new Error('Token is a number');
            }
            const data = JSON.parse(token);
            switch (data.tool) {
              case 'main_sql_db_list_functions':
                thunkAPI.dispatch(
                  addBotToolMessage(`Ingresando a la base de datos.`),
                );
                break;
              case 'timeseries_sql_db_list_functions':
                thunkAPI.dispatch(
                  addBotToolMessage(`Ingresando a la base de datos.`),
                );
                break;
              case 'pubmed_query_tool':
                thunkAPI.dispatch(
                  addBotToolMessage(
                    `Buscando "${data.tool_input.query}" en PubMed.`,
                  ),
                );
                break;
              // case 'google_search':
              //   thunkAPI.dispatch(
              //     addBotToolMessage(`Buscando "${data.tool_input}" en PubMed.`),
              //   );
              //   break;
              // case 'sql_db_list_functions':
              //   thunkAPI.dispatch(
              //     addBotToolMessage(`Ingresando a la base de datos.`),
              //   );
              //   break;
              // case 'sql_db_schema':
              //   thunkAPI.dispatch(
              //     addBotToolMessage(
              //       `Analizando configuración de la base de datos.`,
              //     ),
              //   );
              //   break;
              case 'main_sql_db_query':
              case 'timeseries_sql_db_query':
                const description = get_sql_query_description(data.tool_input);
                if (description) {
                  thunkAPI.dispatch(addBotToolMessage(description));
                }
                break;
              default:
                console.log('Herramienta desconocida:', data.tool);
            }
          } catch (error) {
            // Error when is final response
            if (lastAuthor === 'me' || lastIsTool === 'tool') {
              let tokenProcessed = removePrefix(token);
              thunkAPI.dispatch(addBotMessage(tokenProcessed));
            } else {
              if (token.endsWith('```')) {
                let removeEnding = token.replace('```', '');
                thunkAPI.dispatch(addTokenLastBotMessage(removeEnding));
              } else {
                thunkAPI.dispatch(addTokenLastBotMessage(token));
              }
            }
          }
        },
        () => {
          // Finally callback for ser loading state
          thunkAPI.dispatch(setLoading(false));
        },
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(errorHandler(error));
    }
  },
);

const sendFAQ = createAsyncThunk(
  'chatbot/sendFAQ',
  async (payload, thunkAPI) => {
    const conversationId = thunkAPI.getState().chatbot.current.id;
    const token = thunkAPI.getState().user.data.refresh;
    let response;

    thunkAPI.dispatch(addUserMessage(payload.questionText));

    try {
      const newPayload = {
        ...payload,
        conversationId,
      };

      response = await thunkhandler(
        sendFAQToBot,
        newPayload,
        thunkAPI.dispatch,
        refreshSessionThunk,
        token,
      );

      thunkAPI.dispatch(addBotMessage(response.data.message));
      thunkAPI.dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(errorHandler(error));
    }
  },
);

const startNewConversation = createAsyncThunk(
  'chatbot/startNewConversation',
  async (payload, thunkAPI) => {
    const token = thunkAPI.getState().user.data.refresh;
    const response = await thunkhandler(
      createConversation,
      payload,
      thunkAPI.dispatch,
      refreshSessionThunk,
      token,
    );
    return errorHandler(response.status, response.data, chatbotState.current);
  },
);

const fetchConversationDetails = createAsyncThunk(
  'chatbot/fetchConversationDetails',
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().user.data.refresh;
      const response = await thunkhandler(
        getConversation,
        payload,
        thunkAPI.dispatch,
        refreshSessionThunk,
        token,
      );
      if (response.error) {
        throw new Error(response.error);
      }
      const formattedMessages = [
        {
          author: 'bot',
          type: 'text',
          data: {
            text: '¡Hola! Soy tu asistente virtual en Glik ¿En qué puedo ayudarte hoy?',
          },
        },
        ...response.data.messages.map((msg) => ({
          author: msg.type === 'human' ? 'me' : 'bot',
          type: 'text',
          data: {
            text: msg.data.content,
          },
        })),
      ];
      return {
        id: response.data.id,
        messages: formattedMessages,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        'Error al cargar los detalles de la conversación',
      );
    }
  },
);

const fetchConversationMessages = createAsyncThunk(
  'chatbot/fetchConversationMessages',
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().user.data.refresh;
      const response = await thunkhandler(
        getConversationMessages,
        payload,
        thunkAPI.dispatch,
        refreshSessionThunk,
        token,
      );
      if (response.error) {
        throw new Error(response.error);
      }

      const formattedMessages = [
        // {
        //   author: 'bot',
        //   type: 'text',
        //   data: {
        //     text: '¡Hola! Soy tu asistente virtual en Glik ¿En qué puedo ayudarte hoy?',
        //   },
        // },
        ...response.data.map((msg) => ({
          id: msg.id,
          author: msg.type === 'human' ? 'me' : 'bot',
          type: 'text',
          data: {
            text: msg.data.content,
          },
          createdAt: msg.created_at,
        })),
      ];

      return {
        id: payload.conversationId,
        messages: formattedMessages,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        'Error al cargar los mensajes de la conversación',
      );
    }
  },
);

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState: chatbotState,
  reducers: {
    addUserMessage: (state, action) => {
      state.current.messages.push({
        author: 'me',
        type: 'text',
        data: { text: action.payload },
      });
    },
    addBotMessage: (state, action) => {
      state.current.messages.push({
        author: 'bot',
        type: 'text',
        data: { text: action.payload },
      });
    },
    addBotToolMessage: (state, action) => {
      state.current.messages.push({
        author: 'bot',
        type: 'tool',
        data: { text: action.payload },
      });
    },
    addTokenLastBotMessage: (state, action) => {
      const lastBotMessageIndex = state.current.messages
        .slice()
        .reverse()
        .findIndex((message) => message.author === 'bot');

      if (lastBotMessageIndex !== -1) {
        const trueIndex =
          state.current.messages.length - 1 - lastBotMessageIndex;
        state.current.messages[trueIndex].data.text += action.payload;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingConversations: (state, action) => {
      state.loadingConversations = action.payload;
    },
    setLoadingMessages: (state, action) => {
      state.loadingMessages = action.payload;
    },
    resetChatbotState: (state) => {
      return chatbotState;
    },
    setCurrentConversation: (state, action) => {
      state.current.id = action.payload;
      state.current.messages = [];
      state.isFirstMessage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.fulfilled, (state) => {
        state.isFirstMessage = false;
      })
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sendFAQ.fulfilled, (state) => {
        state.isFirstMessage = false;
      })
      .addCase(sendFAQ.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendFAQ.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.list = action.payload;
        state.error = false;
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.list = action.payload;
        state.error = true;
      })
      .addCase(startNewConversation.fulfilled, (state, action) => {
        state.current.id = action.payload.id;
      })
      .addCase(fetchConversationDetails.fulfilled, (state, action) => {
        state.current = action.payload;
        state.loading = false;
      })
      .addCase(fetchConversationDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchConversationDetails.rejected, (state) => {
        state.loading = false;
        state.error = 'Error al cargar los detalles de la conversación';
      })
      .addCase(fetchConversationMessages.fulfilled, (state, action) => {
        const { id, messages } = action.payload;

        if (state.current.id !== id) {
          state.current.id = id;
          state.current.messages = messages.reverse();
        } else {
          state.current.messages = [
            ...messages.reverse(),
            ...state.current.messages,
          ];
        }

        state.loadingMessages = false;
      })
      .addCase(fetchConversationMessages.pending, (state) => {
        state.loadingMessages = true;
      })
      .addCase(fetchConversationMessages.rejected, (state) => {
        state.loadingMessages = false;
        state.error = 'Error al cargar los mensajes de la conversación';
      });
  },
});

export const startNewConversationThunk = startNewConversation;
export const sendMessageThunk = sendMessage;
export const sendFAQThunk = sendFAQ;
export const fetchConversationsThunk = fetchConversations;
export const fetchConversationDetailsThunk = fetchConversationDetails;
export const fetchConversationMessagesThunk = fetchConversationMessages;
export const {
  addUserMessage,
  addBotMessage,
  addBotToolMessage,
  resetChatbotState,
  addTokenLastBotMessage,
  setLoading,
  setLoadingConversations,
  setLoadingMessages,
  setCurrentConversation,
} = chatbotSlice.actions;
export const chatbotReducer = chatbotSlice.reducer;
